import ArbitrumSvg from "./svgs/arbitrum.svg";
import EnsSvg from "./svgs/ens.svg";
import GmxSvg from "./svgs/gmx.svg";
import HopSvg from "./svgs/hop.svg";
import HydranetSvg from "./svgs/hydranet.svg";
import Layer2DaoSvg from "./svgs/layer2dao.svg";
import MagicSvg from "./svgs/magic.svg";
import MozaicSvg from "./svgs/mozaic.svg";
import OpenDollarSvg from "./svgs/open-dollar.svg";
import PushSvg from "./svgs/push.svg";
import SafeSvg from "./svgs/safe.svg";
import SectorSvg from "./svgs/sector.svg";
import ShapeShiftSvg from "./svgs/shapeshift.svg";
import ShellProtocolSvg from "./svgs/shell-protocol.svg";
import SperaxSvg from "./svgs/sperax.svg";
import SwaprSvg from "./svgs/swapr.svg";
import UniswapSvg from "./svgs/uniswap.svg";
import CompoundSvg from "./svgs/compound.svg";

export type TDaoId =
  | "arbitrum"
  | "ens"
  | "gmx"
  | "hop"
  | "hydranet"
  | "layer2dao"
  | "magic"
  | "mozaic"
  | "open-dollar"
  | "push"
  | "safe"
  | "sector"
  | "shapeshift"
  | "shell-protocol"
  | "sperax"
  | "swapr"
  | "uniswap"
  | "compound";

export interface IDaoAsset {
  id: TDaoId;
  size: number;
}

export default function DaoAsset(props: IDaoAsset) {
  const { id, size } = props;

  switch (id) {
    case "arbitrum":
      return <ArbitrumSvg width={size} height={size} />;
    case "ens":
      return <EnsSvg width={size} height={size} />;
    case "gmx":
      return <GmxSvg width={size} height={size} />;
    case "hop":
      return <HopSvg width={size} height={size} />;
    case "hydranet":
      return <HydranetSvg width={size} height={size} />;
    case "layer2dao":
      return <Layer2DaoSvg width={size} height={size} />;
    case "magic":
      return <MagicSvg width={size} height={size} />;
    case "mozaic":
      return <MozaicSvg width={size} height={size} />;
    case "open-dollar":
      return <OpenDollarSvg width={size} height={size} />;
    case "push":
      return <PushSvg width={size} height={size} />;
    case "safe":
      return <SafeSvg width={size} height={size} />;
    case "sector":
      return <SectorSvg width={size} height={size} />;
    case "shapeshift":
      return <ShapeShiftSvg width={size} height={size} />;
    case "shell-protocol":
      return <ShellProtocolSvg width={size} height={size} />;
    case "sperax":
      return <SperaxSvg width={size} height={size} />;
    case "swapr":
      return <SwaprSvg width={size} height={size} />;
    case "uniswap":
      return <UniswapSvg width={size} height={size} />;
    case "compound":
      return <CompoundSvg width={size} height={size} />;
    default:
      return null;
  }
}
